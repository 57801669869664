<template>
  <el-dialog :visible.sync="dialogVisible" width="55%" append-to-body>
    <div slot="title">
      <h3>
        提交任务
        <i class="memo-style">
          *可查看指定地点附近3公里范围内的tg用户（同时开启查找附近的人的tg用户），该功能1小时内限制查询1次
        </i>
      </h3>
      <p></p>
    </div>
    <el-form label-width="80px" :model="ruleForm" :rules="rules" ref="ruleForm">
      <el-form-item label="标题" prop="title">
        <el-input placeholder="请输入标题" v-model="ruleForm.title"></el-input>
      </el-form-item>
    </el-form>
    <div id="my-map"></div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose" size="small">取 消</el-button>
      <el-button type="primary" size="small" @click="sureFun">
        提 交
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { addApi } from '@/api/geo'
export default {
  data() {
    return {
      dialogVisible: false,
      ruleForm: {},
      rules: {
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }]
      },
      map: null,
      pointInfo: {},
      positionData: {
        lng: 116.404,
        lat: 39.915
      }
    }
  },
  mounted() {},
  methods: {
    locationFun() {
      let that = this
      return new Promise((resolve, reject) => {
        var geolocation = new BMapGL.Geolocation()
        geolocation.getCurrentPosition(function(r) {
          if (this.getStatus() == BMAP_STATUS_SUCCESS) {
            that.positionData.lat = r.point.lat // 获取纬度
            that.positionData.lng = r.point.lng // 获取经度

            resolve()
          } else {
            console.log('Failed to get the current location.')
          }
        })
      })
    },
    initFun() {
      this.dialogVisible = true
      this.locationFun().then(() => {
        this.$nextTick(() => {
          if (this.$refs.ruleForm) {
            this.$refs.ruleForm.resetFields()
          }
          this.ruleForm = {}
          this.initMap().then(() => {
            this.controlFun()
          })
        })
      })
    },
    handleClose() {
      this.dialogVisible = false
    },
    sureFun() {
      let that = this
      that.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (!that.pointInfo.lng || !that.pointInfo.lat) {
            return this.$message({
              message: '请点击地图选择标记点！',
              type: 'warning'
            })
          }
          let obj = {
            lat: that.pointInfo.lat,
            lng: that.pointInfo.lng,
            province: that.pointInfo.province,
            city: that.pointInfo.city,
            title: that.ruleForm.title
          }
          addApi(obj).then(() => {
            this.$message({
              message: '任务添加成功！',
              type: 'success'
            })
            that.pointInfo = {}
            that.dialogVisible = false
            that.$emit('taskEmit')
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 地图初始化
    initMap() {
      let that = this
      return new Promise((resolve, reject) => {
        this.map = new BMapGL.Map('my-map') // 创建Map实例
        this.map.centerAndZoom(
          new BMapGL.Point(this.positionData.lng, this.positionData.lat),
          12
        ) // 初始化地图,设置中心点坐标和地图级别
        this.map.enableScrollWheelZoom(true) // 开启鼠标滚轮缩放

        resolve()
        this.map.addEventListener('click', function(e) {
          // 重置
          that.map.clearOverlays()
          that.pointInfo = {}
          //
          that.pointInfo.lng = e.latlng.lng
          that.pointInfo.lat = e.latlng.lat
          //   获取地区信息
          that.regionalFun(e.latlng.lng, e.latlng.lat).then(() => {
            that.markeFun(e.latlng.lng, e.latlng.lat)
          })
        })
      })
    },
    // 自定义控件
    controlFun() {
      let that = this
      //定义一个控件类
      function ZoomControl() {
        this.defaultAnchor = BMAP_ANCHOR_TOP_LEFT
        this.defaultOffset = new BMapGL.Size(20, 20)
      }
      ZoomControl.prototype = new BMapGL.Control()
      ZoomControl.prototype.initialize = function(map) {
        //创建一个dom元素
        var div = document.createElement('div')
        div.className = 'custom-control'
        //添加文字说明
        div.appendChild(document.createTextNode('清 除'))
        // 绑定点击事件
        div.onclick = () => {
          that.map.clearOverlays()
          that.pointInfo = {}
        }
        // 添加DOM元素到地图中
        map.getContainer().appendChild(div)
        // 将DOM元素返回
        return div
      }
      //创建控件元素
      var myZoomCtrl = new ZoomControl()
      //添加到地图中
      this.map.addControl(myZoomCtrl)
    },

    // 获取地区信息
    regionalFun(lng, lat) {
      return new Promise((resolve, reject) => {
        let that = this
        // 创建地理编码实例
        let geocoder = new BMapGL.Geocoder()
        // 使用地理编码获取地区信息
        geocoder.getLocation(new BMapGL.Point(lng, lat), result => {
          if (result && result.addressComponents) {
            // 获取地区名称
            const { province, city, district } = result.addressComponents
            let clickedAreaName = `${province} ${city} ${district}`
            if (province) {
              that.pointInfo.province = province
            }
            if (city) {
              that.pointInfo.city = city
            }
            resolve()
          } else {
            alert('无法获取地区信息。')
          }
        })
      })
    },
    // 创建标记点
    markeFun(lng, lat) {
      let that = this
      var marker1 = new BMapGL.Marker(new BMapGL.Point(lng, lat))
      this.map.addOverlay(marker1)
      let opts = {
        width: 200, // 信息窗口宽度
        height: 100, // 信息窗口高度
        title: '位置信息' // 信息窗口标题
      }
      let infoWindow = new BMapGL.InfoWindow(
        ` <div>经度：${lng} </div>
        <div>纬度：${lat}</div>
        <div>地区：${that.pointInfo.province || ''} / ${that.pointInfo.city ||
          ''}</div>`,
        opts
      ) // 创建信息窗口对象
      let point = new BMapGL.Point(lng, lat)
      this.map.openInfoWindow(infoWindow, point) //开启信息窗口
      //
      let circle = new BMapGL.Circle(point, 3000, {
        fillColor: 'red', // 设置圆形的填充颜色
        strokeWeight: 1, // 圆形边线宽度
        strokeColor: 'blue' // 圆形边线颜色
      })
      that.map.addOverlay(circle)
    },
    // 位置授权
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.showPosition,
          this.showError
        )
      } else {
        alert('浏览器不支持地理位置获取。')
      }
    },

    showPosition(position) {
      const latitude = position.coords.latitude
      const longitude = position.coords.longitude
      document.getElementById(
        'location'
      ).innerHTML = `您的位置是：纬度 ${latitude}，经度 ${longitude}`
    },
    showError(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          alert('用户拒绝了位置请求。')
          break
        case error.POSITION_UNAVAILABLE:
          alert('位置信息不可用。')
          break
        case error.TIMEOUT:
          alert('获取位置信息超时。')
          break
        case error.UNKNOWN_ERROR:
          alert('发生未知错误。')
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#my-map {
  width: 100%;
  height: 55vh;
}
/* 去掉百度地图logo */
::v-deep .anchorBL {
  display: none !important;
}
::v-deep .custom-control {
  background: #409eff;
  padding: 5px 10px;
  color: white;
  user-select: none;
  cursor: pointer;
  border-radius: 2px;
}
.memo-style {
  font-size: 12px;
  color: red;
}
::v-deep .el-dialog__body {
  padding-top: 0;
}
</style>
