<template>
  <el-dialog
    :title="pointInfo.title"
    :visible.sync="dialogVisible"
    width="55%"
    append-to-body
  >
    <el-tabs v-model="activeName" v-loading="loading">
      <el-tab-pane label="地图" name="second">
        <div id="detail-map"></div>
      </el-tab-pane>
      <el-tab-pane label="列表" name="first">
        <el-table :data="tableData" height="450" border>
          <el-table-column prop="userId" label="用户ID"></el-table-column>
          <el-table-column prop="userUsername" label="用户名"></el-table-column>
          <el-table-column prop="userFirstName" label="姓"></el-table-column>
          <el-table-column prop="userLastName" label="名"></el-table-column>
          <el-table-column label="经纬度" width="200">
            <template slot-scope="scope">
              {{ scope.row.lng }} / {{ scope.row.lat }}
            </template>
          </el-table-column>
          <el-table-column prop="distance" label="距离"></el-table-column>
          <el-table-column label="地区">
            <template slot-scope="scope">
              {{ scope.row.province }} / {{ scope.row.city }}
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>

    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose" size="small">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { detailApi } from '@/api/geo'
import icon17 from '@/assets/imgs/i17.png'
export default {
  data() {
    return {
      activeName: 'second',
      dialogVisible: false,
      ruleForm: {},
      rules: {
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }]
      },
      map: null,
      loading: false,
      pointInfo: {},
      tableData: []
    }
  },
  mounted() {},
  methods: {
    initFun(row) {
      this.map = null
      this.activeName = 'second'
      this.dialogVisible = true

      this.getData(row.id).then(() => {
        // 地图初始化
        this.$nextTick(() => {
          this.initMap().then(() => {
            this.markeFun(this.pointInfo.lng, this.pointInfo.lat)
            let centerPoint = new BMapGL.Point(
              this.pointInfo.lng,
              this.pointInfo.lat
            )
            this.batchAdd(this.tableData, centerPoint)
          })
        })
      })
    },
    handleClose() {
      this.dialogVisible = false
    },
    getData(id) {
      return new Promise((resolve, reject) => {
        let that = this
        that.loading = true
        detailApi(id).then(data => {
          that.pointInfo = { ...data }
          that.tableData = [...data.geoUserList]
          that.loading = false
          resolve()
        })
      })
    },
    // 地图初始化
    initMap() {
      let that = this
      return new Promise((resolve, reject) => {
        this.map = new BMapGL.Map('detail-map') // 创建Map实例
        let point = new BMapGL.Point(this.pointInfo.lng, this.pointInfo.lat)
        this.map.centerAndZoom(point, 13) // 初始化地图,设置中心点坐标和地图级别
        this.map.enableScrollWheelZoom(true) // 开启鼠标滚轮缩放
        //获取最大值（范围）
        let maxItem = { distance: 3000 }
        if (this.tableData.length > 0) {
          maxItem = this.tableData.reduce(
            (max, item) => (item.distance > max.distance ? item : max),
            this.tableData[0]
          )
        }

        // 圆范围
        const circle = new BMapGL.Circle(point, maxItem.distance)
        this.map.addOverlay(circle)
        //

        resolve()
      })
    },
    // 自定义控件
    controlFun() {
      let that = this
      //定义一个控件类
      function ZoomControl() {
        this.defaultAnchor = BMAP_ANCHOR_TOP_LEFT
        this.defaultOffset = new BMapGL.Size(20, 20)
      }
      ZoomControl.prototype = new BMapGL.Control()
      ZoomControl.prototype.initialize = function(map) {
        //创建一个dom元素
        var div = document.createElement('div')
        div.className = 'custom-control'
        //添加文字说明
        div.appendChild(document.createTextNode('清 除'))
        // 绑定点击事件
        div.onclick = () => {
          that.map.clearOverlays()
          that.pointInfo = {}
        }
        // 添加DOM元素到地图中
        map.getContainer().appendChild(div)
        // 将DOM元素返回
        return div
      }
      //创建控件元素
      var myZoomCtrl = new ZoomControl()
      //添加到地图中
      this.map.addControl(myZoomCtrl)
    },

    // 获取地区信息
    regionalFun(lng, lat) {
      return new Promise((resolve, reject) => {
        let that = this
        // 创建地理编码实例
        let geocoder = new BMapGL.Geocoder()
        // 使用地理编码获取地区信息
        geocoder.getLocation(new BMapGL.Point(lng, lat), result => {
          if (result && result.addressComponents) {
            // 获取地区名称
            const { province, city, district } = result.addressComponents
            let clickedAreaName = `${province} ${city} ${district}`

            resolve(province, city)
          } else {
            alert('无法获取地区信息。')
          }
        })
      })
    },
    // 创建标记点
    markeFun(lng, lat) {
      let that = this
      var marker1 = new BMapGL.Marker(new BMapGL.Point(lng, lat))
      this.map.addOverlay(marker1)
      marker1.addEventListener('click', function(e) {
        that.regionalFun(lng, lat).then((province, city) => {
          // 信息窗口
          let opts = {
            width: 200, // 信息窗口宽度
            height: 130, // 信息窗口高度
            title: '位置信息' // 信息窗口标题
          }
          let infoWindow = new BMapGL.InfoWindow(
            ` <div>经度：${lng} </div>
        <div>纬度：${lat}</div>
        <div>处理状态：${
          that.pointInfo.state == 1 ? '处理完成' : '处理中'
        }</div>
        <div>地区：${that.pointInfo.province} / ${that.pointInfo.city}</div>
         
       `,
            opts
          ) // 创建信息窗口对象
          let point = new BMapGL.Point(lng, lat)
          that.map.openInfoWindow(infoWindow, point) //开启信息窗口
        })
      })
    },
    // 根据中心点、距离和方位角计算目标点的经纬度坐标
    computeDestinationPoint(centerPoint, distance, bearing, itemInfo) {
      let that = this

      const EarthRadius = 6371000 // 地球半径（单位：米）

      // 将距离转换为弧度
      const radianDistance = distance / EarthRadius

      // 将方位角转换为弧度
      const radianBearing = (bearing * Math.PI) / 180

      // 计算目标点的纬度
      const lat1 = (centerPoint.lat * Math.PI) / 180
      const lat2 = Math.asin(
        Math.sin(lat1) * Math.cos(radianDistance) +
          Math.cos(lat1) * Math.sin(radianDistance) * Math.cos(radianBearing)
      )

      // 计算目标点的经度
      const lon1 = (centerPoint.lng * Math.PI) / 180
      const lon2 =
        lon1 +
        Math.atan2(
          Math.sin(radianBearing) * Math.sin(radianDistance) * Math.cos(lat1),
          Math.cos(radianDistance) - Math.sin(lat1) * Math.sin(lat2)
        )

      // 将经纬度转换为度
      const targetLat = (lat2 * 180) / Math.PI
      const targetLng = (lon2 * 180) / Math.PI
      //   添加标记点
      let p = new BMapGL.Point(targetLng, targetLat)
      var myIcon = new BMapGL.Icon(icon17, new BMapGL.Size(20, 26))
      let marker = new BMapGL.Marker(p, {
        icon: myIcon
      })
      // marker.setOffset(new BMapGL.Size(-1, -10))
      // marker.setLabel(
      //   new BMapGL.Label(null, { offset: new BMapGL.Size(-1, 10) })
      // )

      this.map.addOverlay(marker)
      // 监听标记的点击事件
      marker.addEventListener('click', function(e) {
        let lng = e.latLng.lng
        let lat = e.latLng.lat
        that.regionalFun(lng, lat).then((province, city) => {
          // 信息窗口
          let opts = {
            width: 200, // 信息窗口宽度
            height: 230, // 信息窗口高度
            title: '用户信息' // 信息窗口标题
          }
          let infoWindow = new BMapGL.InfoWindow(
            ` <div>经度：${lng} </div>
        <div>纬度：${lat}</div>
        <div>用户名：${itemInfo.userUsername ? itemInfo.userUsername : ''}</div>
        <div>用户ID：${itemInfo.userId}</div>
        <div>姓：${itemInfo.userFirstName ? itemInfo.userFirstName : ''}</div>
        <div>名：${itemInfo.userLastName ? itemInfo.userLastName : ''}</div>
        <div>距离：${itemInfo.distance}</div>
        <div>地区：${itemInfo.province} / ${itemInfo.city}</div>
       `,
            opts
          ) // 创建信息窗口对象
          let point = new BMapGL.Point(lng, lat)
          that.map.openInfoWindow(infoWindow, point) //开启信息窗口
        })
      })
    },
    // 批量添加
    batchAdd(arr, centerPoint) {
      if (!Array.isArray(arr) || arr.length == 0) return
      this.$nextTick(() => {
        arr.forEach((item, i) => {
          let num = Math.random() * 360
          this.computeDestinationPoint(centerPoint, item.distance, num, item)
        })
      })
    },
    // 获取地区信息
    regionalFun(lng, lat) {
      return new Promise((resolve, reject) => {
        let that = this
        // 创建地理编码实例
        let geocoder = new BMapGL.Geocoder()
        // 使用地理编码获取地区信息
        geocoder.getLocation(new BMapGL.Point(lng, lat), result => {
          if (result && result.addressComponents) {
            // 获取地区名称
            const { province, city, district } = result.addressComponents
            let clickedAreaName = `${province} ${city} ${district}`
            if (province) {
              that.pointInfo.province = province
            }
            if (city) {
              that.pointInfo.city = city
            }
            resolve(province, city)
          } else {
            alert('无法获取地区信息。')
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#detail-map {
  width: 100%;
  height: 55vh;
}
/* 去掉百度地图logo */
::v-deep .anchorBL {
  display: none !important;
}
::v-deep .custom-control {
  background: #409eff;
  padding: 5px 10px;
  color: white;
  user-select: none;
  cursor: pointer;
  border-radius: 2px;
}
</style>
