import request from '@/plugins/axios'

export function geoTaskApi(page, limit, data) {
  return request({
    url: `/geoTask/${page}/${limit}`,
    method: 'post',
    data
  })
}
export function addApi(data) {
  return request({
    url: `/geoTask`,
    method: 'post',
    data
  })
}
export function detailApi(id) {
  return request({
    url: `/geoTask/${id}`
  })
}
