<template>
  <div class="cutting-page">
    <div class="top">
      <el-select v-model="searchForm.state" size="small" placeholder="状态">
        <el-option label="全部" :value="null"></el-option>
        <el-option label="处理中" :value="0"></el-option>
        <el-option label="处理完成" :value="1"></el-option>
      </el-select>

      <el-button
        type="primary"
        size="mini"
        style=" margin-left:10px"
        @click="searchFun"
        :loading="loading"
      >
        查询
      </el-button>
      <el-button
        class="el-icon-refresh-left"
        size="mini"
        style=" margin-left:10px"
        @click="refresh"
        :loading="loading"
      >
        刷新
      </el-button>
      <el-button
        type="primary"
        size="mini"
        style=" margin-left:10px"
        @click="addFun"
        :loading="loading"
      >
        提交任务
      </el-button>
    </div>
    <div class="child" v-loading="loading">
      <el-table
        style="margin-bottom:10px"
        :row-style="{ height: 0 + 'px' }"
        :cell-style="{ padding: '5px 0' }"
        header-row-class-name="tableheader"
        :data="list"
        border
        :height="windowsHeight - 220"
      >
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column label="地区">
          <template slot-scope="scope">
            {{ scope.row.province }} / {{ scope.row.city }}
          </template>
        </el-table-column>
        <el-table-column label="经纬度">
          <template slot-scope="scope">
            {{ scope.row.lng }} / {{ scope.row.lat }}
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.state == 1" type="success">
              处理完成
            </el-tag>
            <el-tag size="mini" v-else>处理中</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="提交时间"></el-table-column>

        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.state == 1"
              type="primary"
              size="mini"
              @click="detailFun(scope.row)"
            >
              详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="total"
        :page-size="limit"
        :currentPage="current"
        :pagerCount="5"
        :background="false"
        :margin="0"
        :sizesArr="[30, 50, 100]"
        @currentChange="currentChange"
        @sizeChange="sizeChange"
      />
    </div>

    <!-- 添加 -->
    <addPage @taskEmit="taskEmitFun" ref="addPageRef" />
    <detailPage ref="detailPageRef" />
  </div>
</template>

<script>
import { geoTaskApi } from '@/api/geo'
import addPage from './components/Add.vue'
import detailPage from './components/Detail.vue'
export default {
  name: 'geo',
  components: {
    addPage,
    detailPage
  },
  data() {
    return {
      loading: false,
      list: [],
      current: 1,
      limit: 30,
      total: 0,
      searchForm: {},
      placeVisible: false
    }
  },
  computed: {
    windowsHeight() {
      return this.$store.state.windowData.height
    }
  },

  created() {
    this.getPage()
  },
  methods: {
    // 关闭弹框
    closePlace() {
      this.placeVisible = false
    },
    searchFun() {
      this.current = 1
      this.getPage()
    },
    refresh() {
      this.getPage()
    },
    taskEmitFun() {
      this.getPage()
    },
    getPage() {
      this.loading = true
      geoTaskApi(this.current, this.limit, this.searchForm).then(data => {
        this.list = data.items
        this.total = data.all_count
        this.loading = false
      })
    },
    currentChange(val) {
      this.current = val
      this.getPage()
    },
    sizeChange(val) {
      this.current = 1
      this.limit = val
      this.getPage()
    },
    detailFun(targetId, targetType, id) {
      if (targetType == 1) {
        this.$router.push('/group/detail/' + targetId)
      } else if (targetType == 2) {
        this.$router.push('/user/detail/' + targetId)
      }
      readApi(id)
    },
    // 添加
    addFun() {
      this.$refs.addPageRef.initFun()
    },
    // 详情
    detailFun(row) {
      this.$refs.detailPageRef.initFun(row)
    }
  }
}
</script>

<style lang="scss" scoped>
.cutting-page {
  padding: 10px;
  width: 100%;
  height: calc(100vh - 80px);
  background: #f0f2f5;
  .top {
    padding: 10px;
    background: white;
    box-shadow: rgb(216 217 217) 0px 1px 3px 0px;
    border-radius: 5px;
  }
  .child {
    margin-top: 5px;
    padding: 10px;
    padding-bottom: 1px;
    background: white;
    box-shadow: rgb(216 217 217) 0px 1px 3px 0px;
    border-radius: 5px;
  }
  ::v-deep .tableheader th {
    background: rgb(243, 247, 249);
    padding: 8px 0;
  }
  .cutting-table-span {
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: rgb(37, 122, 233);
    }
  }
}
</style>
